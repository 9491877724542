import React, { useState } from "react";
import Document from "../../components/svgs/Document/Document";
import { wrap, link, svg, tabDiv } from "./Library.module.scss";
import useLang from "../../contexts/Language/LanguageContext";
import statut from "../../files/Statut I MI Boke.pdf";
import statutEn from "../../files/The Statute of IYDB.pdf";
import report2019 from "../../files/Izvještaj o radu za 2019.  godinu.pdf";
import report2019En from "../../files/Report on work for 2019.pdf";
import reportFin2020 from "../../files/Finansijski izvještaj - Financial report 2020..pdf";
import reportFin2019 from "../../files/Finansijski izvještaj - Financial report 2019.pdf";
import pravilnik from "../../files/Pravilnik I MI Boke o opisu i sistematizaciji radnih mjesta...pdf";
import policyMne from "../../files/PolicyMNE.pdf";
import policyEn from "../../files/PolicyEN.pdf";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import translate from "../../utils/lang/langHash";
import useIsMobile from "../../utils/is-mobile/isMobile";
import kotorOSI from "../../files/LetakKotor.pdf";
import OSIrights from "../../files/EduAkcija.pdf";
import izvjestavanjeMNE from "../../files/Izvjestavanje_lokalnih_medija.pdf";
import reportingEN from "../../files/Local_Media_Reporting.pdf";
import researchMNE from "../../files/istrazivanje-diskriminacija.pdf";
import researchEN from "../../files/research-discr.pdf";
import broCir from "../../files/BrosuraCirilic.pdf";
import broLat from "../../files/BrosuraLatinic.pdf";
import broRom from "../../files/BrosuraRomski.pdf";
import broAlb from "../../files/BrosuraAlbanski.pdf";
import izv2020 from "../../files/2020.pdf";
import izv2020en from "../../files/reportonwork2020.pdf";
import izv2021 from "../../files/2021.pdf";
import bilans from "../../files/bilans.pdf";
import revizor from "../../files/revizor.pdf";
import bilanseng from "../../files/bilanseng.pdf";
import sputa from "../../files/maknimisesputa.pdf";
import fin2022 from "../../files/fin2022.pdf";
import rev2022 from "../../files/rev2022.pdf";
import rev2022en from "../../files/rev2022en.pdf";
import repmne from "../../files/reporten.pdf";
import repen from "../../files/reportmne.pdf";
import analysis from "../../files/analysis.pdf";
import samostalan from "../../files/samostalan.pdf";
import samostalanEn from "../../files/independent.pdf";
import pravilnikUsluge from "../../files/Pravilnik-usluge.pdf";
import strategic from "../../files/Strategic.pdf";
import programpa from "../../files/programpa.pdf";
import programpk from "../../files/programpk.pdf";
import proczass from "../../files/proczasss.pdf";
import prepozz from "../../files/prepozz.pdf";
import ROB from "../../files/ROB.pdf";
import brief from "../../files/BriefGRB.pdf";
import procedura from "../../files/procedura.pdf";

const LibraryItems = [
  {
    mne: "Procedura o postupanju po pritužbama korisnika",
    en: "",
    docMne: procedura,
    docEn: null,
  },
  {
    mne: "Statut I MI Boke",
    en: "Statute",
    docMne: statut,
    docEn: statutEn,
  },
  {
    mne: "Pravilnik o unutrašnjoj organizaciji, sistematizaciji i angažovanju spoljnih saradnika/ca",
    en: "Rulebook on internal organization, systematization and engagement of external associates",
    docMne: pravilnik,
  },
  {
    mne: "Pravilnik I MI Boke o pružanju usluga",
    en: "",
    docMne: pravilnikUsluge,
    docEn: null,
  },
  {
    mne: "Procedura o načinu zaštite podataka o ličnosti korisnika/ca usluga",
    en: "",
    docMne: proczass,
    docEn: null,
  },
];

const ReportItems = [
  {
    mne: "Izvještaj o radu za 2019. godinu",
    en: "Report on work for 2019.",
    docMne: report2019,
    docEn: report2019En,
  },
  {
    mne: "Finansijski izvještaj za 2019. godinu",
    en: "Financial report for 2019.",
    docMne: reportFin2019,
  },
  {
    mne: "Izvještaj o radu za 2020. godinu",
    en: "Report on work for 2020.",
    docMne: izv2020,
    docEn: izv2020en,
  },
  {
    mne: "Finansijski izvještaj za 2020. godinu",
    en: "Financial report for 2020.",
    docMne: reportFin2020,
  },
  {
    mne: "Izvještaj o radu za 2021. godinu",
    en: "Report on work for 2021.",
    docMne: izv2021,
    docEn: izv2021,
  },
  {
    mne: "Finansijski izvještaj za 2021. godinu",
    en: "Financial report 2021.",
    docMne: bilans,
    docEn: bilans,
  },
  {
    mne: "Izvještaj o revizorskom pregledu finansijskih iskaza 2021.",
    en: "Audit Financial Statements Report 2021.",
    docMne: revizor,
    docEn: bilanseng,
  },
  {
    mne: "Finansijski izvještaj za 2022. godinu",
    en: "Financial report 2022.",
    docMne: fin2022,
    docEn: fin2022,
  },
  {
    mne: "Izvještaj o revizorskom pregledu finansijskih iskaza 2022.",
    en: "Audit Financial Statements Report 2022.",
    docMne: rev2022,
    docEn: rev2022en,
  },
  {
    mne: "",
    en: "Strategic Plan of the IYDB 2023-2025",
    docMne: null,
    docEn: strategic,
  },
];

const publicationsItems = [
  {
    mne: "Sažetak ROB o zapošljavanju i socijalnoj zaštiti OSI",
    en: "Policy Brief of GRB in the Field on Employment and Social Protection of PWDs",
    docMne: ROB,
    docEn: brief,
  },
  {
    mne: "Policy Brief - ROB u oblasti zapošljavanja OSI u Crnoj Gori",
    en: "Policy Brief - GRB in the Field of Employment of PWDs in Montenegro",
    docMne: policyMne,
    docEn: policyEn,
  },
  {
    mne: "Letak - EduAkcija za inkluzivnost Kotora",
    en: "Flyer - EduAction for Inclusiveness of Kotor",
    docMne: kotorOSI,
    docEn: kotorOSI,
  },
  {
    mne: "Letak - EduAkcija za prava OSI",
    en: "Flyer - EduAction for Disability Rights",
    docMne: OSIrights,
    docEn: OSIrights,
  },
  {
    mne: "Izvještavanje lokalnih medija o i za OSI",
    en: "Local media reporting for and on PWDs",
    docMne: izvjestavanjeMNE,
    docEn: reportingEN,
  },
  {
    mne: "Istraživanje o nasilju i diskriminaciji nad ženama, djevojkama i djevojčicama s invaliditetom i vidovima podrške koja je potrebna njima i njihovim porodicama",
    en: "The Research on Violence and Discrimination against Women and Girls with Disabilities and the Types of Support they and their Families Need",
    docMne: researchMNE,
    docEn: researchEN,
  },
  {
    mne: "Brošura o prepoznavanju nasilja i povreda ravnopravnosti žena sa invaliditetom - crnogorski jezik ćirilica",
    en: "Brochure on recognition of violence and violations of the equality of women with disabilities - Montenegrin Cyrillic language",
    docMne: broCir,
    docEn: broCir,
  },
  {
    mne: "Brošura o prepoznavanju nasilja i povreda ravnopravnosti žena sa invaliditetom - crnogorski jezik latinica",
    en: "Brochure on recognition of violence and violations of the equality of women with disabilities - Montenegrin Latinic language",
    docMne: broLat,
    docEn: broLat,
  },
  {
    mne: "Broshurë „Njohja e dhunës dhe shkelja e barazisë së grave me aftësi të kufizuara” - albanski jezik",
    en: "Brochure on recognition of violence and violations of the equality of women with disabilities - Albanian language",
    docMne: broAlb,
    docEn: broAlb,
  },
  {
    mne: "Brošura „Pindžardipe zornalikane maripasko thaj dukhajpe jekhipasko e romnjengo bangipasa” - romski jezik",
    en: "Brochure on recognition of violence and violations of the equality of women with disabilities - Romany language",
    docMne: broRom,
    docEn: broRom,
  },
  {
    mne: "Letak o pravima i obavezama OSI u saobracaju",
    en: "Brochure on rights of PWDs in traffic",
    docMne: sputa,
    docEn: sputa,
  },
  {
    mne: "Izvještaj o preduzetništvu OSI u Crnoj Gori",
    en: "The Report on Enterpreneurship of PWDs in Montenegro",
    docMne: repmne,
    docEn: repen,
  },
  {
    mne: "",
    en: "Situational Analysis of the Rights of Persons with Disabilities Montenegro",
    docMne: null,
    docEn: analysis,
  },
  {
    mne: "Brošura o uslugama I MI Boke za samostalan život OSI",
    en: "Brochure on the services of the IYDB for the independent life of PWDs",
    docMne: samostalan,
    docEn: samostalanEn,
  },
  {
    mne: "Program pružanja usluga personalne asistencije ",
    en: "",
    docMne: programpa,
    docEn: null,
  },
  {
    mne: "Program pružanja usluga personalne pomoći u kući za odrasle OSI",
    en: "",
    docMne: programpk,
    docEn: null,
  },
  {
    mne: "Brošura o prepoznavanju nasilja i povreda ravnopravnosti žena s invaliditetom u lako razumljivom formatu",
    en: "",
    docMne: prepozz,
    docEn: null,
  },
];

export default function Library() {
  const lang = useLang();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useIsMobile();
  function filterPublications(pubs) {
    return pubs.filter((p) => {
      if (lang === "mne") return Boolean(p.docMne);
      return Boolean(p.docEn);
    });
  }
  return (
    <>
      <Tabs
        orientation={isMobile ? "vertical" : "horizontal"}
        value={value}
        onChange={handleChange}
        aria-label="odaberite link"
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab
          id="general"
          aria-controls="library-general"
          style={{ color: "white" }}
          label={lang === "mne" ? "Opšta akta" : "General documents"}
        />
        <Tab
          id="plans"
          aria-controls="library-plans"
          style={{ color: "white" }}
          label={
            lang === "mne"
              ? "Planovi i izvještaji o radu"
              : "Plans and reports on work"
          }
        />
        <Tab
          id="publications"
          aria-controls="library-publications"
          style={{ color: "white" }}
          label={lang === "mne" ? "Publikacije" : "Publications"}
        />
      </Tabs>
      {value === 0 && (
        <div id="library-general" aria-labelledby="general" className={tabDiv}>
          <h2>
            {lang === "mne"
              ? "Opšta akta I MI Boke"
              : "General Documents of the IYDB"}
          </h2>
          {filterPublications(LibraryItems).map((d) => (
            <div key={d.mne} className={wrap}>
              <div className={svg}>
                <Document />
              </div>
              <a
                className={link}
                href={lang === "mne" ? d.docMne : d.docEn ? d.docEn : d.docMne}
                download
              >
                {lang === "mne" ? d.mne : d.en ? d.en : d.mne}
              </a>
            </div>
          ))}
        </div>
      )}
      {value === 1 && (
        <div id="library-plans" aria-labelledby="plans" className={tabDiv}>
          <h2>
            {lang === "mne"
              ? "Planovi i izvještaji o radu"
              : "Plans and reports on work"}
          </h2>
          {filterPublications(ReportItems).map((d) => (
            <div key={d.mne} className={wrap}>
              <div className={svg}>
                <Document />
              </div>
              <a
                className={link}
                href={lang === "mne" ? d.docMne : d.docEn ? d.docEn : d.docMne}
                download
              >
                {lang === "mne" ? d.mne : d.en ? d.en : d.mne}
              </a>
            </div>
          ))}
        </div>
      )}
      {value === 2 && (
        <div
          id="library-publications"
          aria-labelledby="publications"
          className={tabDiv}
        >
          <h2>{lang === "mne" ? "Publikacije" : "Publications"}</h2>
          {filterPublications(publicationsItems).map((d) => (
            <div key={d.mne} className={wrap}>
              <div className={svg}>
                <Document />
              </div>
              <a
                className={link}
                href={lang === "mne" ? d.docMne : d.docEn ? d.docEn : d.docMne}
                download
              >
                {lang === "mne" ? d.mne : d.en ? d.en : d.mne}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
